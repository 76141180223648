import { Paginated } from '@feathersjs/feathers';
import { Filter, Program } from '@wsb_dev/datafi-shared/lib/types';
import { filtersToQuery } from '@wsb_dev/datafi-shared/lib/util/fields/filtersToQuery';
import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { IStoreConfig } from '../../services/util/StoreManager';
import { ProgramSurvey } from '../../types/ProgramSurvey';
import { projectFilters } from '../../types/Project';
import { ensureNumber } from '../../util/numbers/ensureNumber';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';
import { FilterDialog } from '../../components/filter-dialog/filter-dialog';
import { DialogService } from 'aurelia-dialog';
import { queryToFilter } from '@wsb_dev/datafi-shared/lib/util/fields/queryToFilter';

@autoinject
export class DashboardStoreConfig {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: IStoreConfig;
    @bindable programId: number;
    @bindable surveyId: number;
    @bindable offlineService: boolean;
    @bindable mapGeometry: boolean;

    filters: Filter[];
    program: Program;
    dfpSurveys: ProgramSurvey[];
    koboSurveys: ProgramSurvey[];
    selectedSurvey: ProgramSurvey;

    constructor(
        private api: DatafiProAPI,
        private dialogService: DialogService,
    ) { }

    async bind() {

        this.programId = ensureNumber(this.programId);
        await this.api.programs.get(this.programId).then((program) => this.program = program);
        await this.api.programSurveys.find({
            query: {
                $select: ['id', 'title', 'surveySchema', 'survey_type'],
                program_id: this.programId,
                $limit: 100,
            },
        }).then((result: Paginated<ProgramSurvey>) => {
            this.dfpSurveys = result.data.filter((sv) => sv.survey_type === 'dfp-form');
            this.koboSurveys = result.data.filter((sv) => sv.survey_type === 'kobo-toolbox-survey');
        });

        if (!this.value.query) {
            this.value.query = {};
        }

        if (!this.value.query.$limit) {
            this.value.query.$limit = 5000;
        }

        if (this.value.query.survey_id) {
            this.surveyId = this.value.query.survey_id;
            this.surveyIdChanged(this.surveyId);
        }

        if (this.value.query.$modify?.toGeoJSON) {
            this.mapGeometry = true;
        }

        this.value.query.$client ? this.offlineService = false : this.offlineService = true;

        this.setupFields();
        this.setupQuery();
    }

    surveyIdChanged(id: number) {
        if (id) {
            this.selectedSurvey = this.dfpSurveys.find((survey) => survey.id === id);
            // nothing in the regular surveys, try kobo
            if (!this.selectedSurvey) {
                this.selectedSurvey = this.koboSurveys.find((survey) => survey.id === id);
            }
        }
    }

    setupFields() {
        let filters = [];
        if (this.value.service === 'api/v1/projects') { // Projects
            if (this.program.projectSchema.length) {
                filters = [
                    ...projectFilters,
                    {
                        type: 'object',
                        name: 'metadata',
                        label: 'Project Fields',
                        fields: this.program.projectSchema,
                    },
                ] as Filter[];
            } else {
                filters = [...projectFilters] as Filter[];
            }
        } else  { // Datafi Pro or Kobo survey
            if (!this.selectedSurvey) {
                this.surveyIdChanged(this.value.query.survey_id);
            }
            filters = [
                ...this.selectedSurvey?.surveySchema || [],
            ] as Filter[];
        }
        filters = flattenFields(filters);
        this.filters = queryToFilter(filters, [], this.value.query);
    }

    setupQuery(){

        if (this.value.service === 'api/v1/programs-users-roles'){
            this.value.query.$eager = '[user]';
            // fetch all data for kobo surveys
        } else if(this.value.service === 'api/v2/surveys') {
            this.value.query.$data = true;
            this.value.query.$limit = 1000;
            this.value.query.$modify = undefined;
        } else {
            this.value.query.$eager = undefined;
        }
    }

    reset() {
        this.filters = undefined;

        this.value = {
            ...this.value,
            query: {
                survey_id: this.surveyId,
                program_id: this.programId,
                $limit: 5000,
            },
        };

        this.offlineServiceChanged();
        this.mapGeometryChanged();
        this.setupFields();
        this.setupQuery();
    }

    mapGeometryChanged() {
        if (!this.value.query?.$modify) this.value.query.$modify = {};
        this.mapGeometry ? this.value.query.$modify.toGeoJSON = [] : delete this.value.query?.$modify?.toGeoJSON;
    }

    offlineServiceChanged() {
        this.offlineService ? delete this.value.query.$client : this.value.query.$client = 'no dashboard';
    }

    updateFilters() {
        return this.dialogService.open({
            viewModel: FilterDialog,
            model: {
                filterProperties: this.filters,
            },
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            this.filters = result.output;
            this.updateValue(result.output);
        });
    }

    updateValue(filters: Filter[]) {
        const programId = this.value.query?.program_id;
        const filterQuery = filtersToQuery(filters);

        this.value = {
            ...this.value,
            query: {
                ...filterQuery,
                survey_id: this.surveyId,
                program_id: programId,
            },
        };

        this.offlineServiceChanged();
        this.mapGeometryChanged();
        this.value = this.removeEmptyObjects(this.value);
    }

    removeEmptyObjects(data) {
        for (const key in data) {
            const item = data[key];
            if (typeof (item) === 'object') {
                if (Object.keys(item).length === 0 && key !== 'toGeoJSON' && key !== 'checkboxQuery') {
                    delete data[key];
                } else {
                    this.removeEmptyObjects(item);
                }
            }
        }
        return data;
    }
}
