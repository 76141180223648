import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { bindable, bindingMode } from 'aurelia-framework';

export class FieldListSearch {
    @bindable value: Field;
    @bindable selectedField: Field;
    @bindable options: Field[];
    @bindable currentOptions: Field[];
    @bindable label: string;
    @bindable outlined: boolean;

    attached() {
        this.selectedField = this.value;
        this.currentOptions = this.options;
    }

    selectedFieldChanged(field) {
        if (field) {
            this.value = field;
        } else {
            this.selectedField = this.value;
        }
    }

    optionsChanged(options) {
        if (JSON.stringify(options) !== JSON.stringify(this.currentOptions) || !this.currentOptions) {
            this.currentOptions = options;
        }
    }
}
